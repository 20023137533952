import "core-js/modules/es.array.push.js";
import { schoolPage, UpdateAllSchool, UpdateAll } from "@/api/api";
import { stateList } from "@/utils/config";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      areaValue: [],
      cityList: [],
      Info: null,
      dialogFormVisible: false,
      stateList: stateList,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0,
        province: '',
        city: ''
      },
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
    let data = [];
    for (let i in this.$root.cityList) {
      let data1 = {
        value: this.$root.cityList[i].value,
        label: this.$root.cityList[i].label,
        children: []
      };
      for (let j in this.$root.cityList[i].children) {
        data1.children.push({
          value: this.$root.cityList[i].children[j].value,
          label: this.$root.cityList[i].children[j].label,
          children: []
        });
      }
      data.push(data1);
    }
    this.cityList = data;
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.province = '';
      this.pageInfo.city = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      schoolPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    seleCity(e) {
      this.pageInfo.province = e[0] || '';
      this.pageInfo.city = e[1] || '';
    },
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/admin/adminSchool/schoolEdit",
        query: {
          Info: data
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    updateAll() {
      UpdateAll().then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.search();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    updateSchoolAll() {
      let user_type = localStorage.getItem('userType');
      if (user_type == 1) {} else {
        this.$root.ElMessage.error('身份信息异常');
        return false;
      }
      this.$root.ElMessageBox.confirm('同步数据，操作数据频繁，可能会短暂的影响用户使用，建议在访问量小的时候操作,你确定要现在操作吗?', '提示', {
        confirmButtonText: '继续操作',
        cancelButtonText: '我再想想',
        type: 'warning',
        draggable: true
      }).then(() => {
        this.sendUpdate();
      }).catch(() => {});
    },
    sendUpdate() {
      UpdateAllSchool().then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.search();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};